import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import { Fragment } from 'react'
import { usePromiseTracker } from 'react-promise-tracker'

const PageLoader: React.FC = () => {
  const { promiseInProgress } = usePromiseTracker({ area: 'page-loader' })

  return (
    <Fragment>
      {promiseInProgress && (
        <Box sx={{ width: '100%', position: 'fixed', top: 0 }}>
          <LinearProgress />
        </Box>
      )}
    </Fragment>
  )
}

export default PageLoader
