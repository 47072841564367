import { makeAutoObservable } from 'mobx'
import { BreadcrumbItem } from '../models/breadcrumb.model'

export class BreadcrumbStore {
  private _breadcrumbItems: BreadcrumbItem[]

  constructor() {
    makeAutoObservable(this)
    this._breadcrumbItems = []
  }

  setBreadcrumbItems = (breadcrumb: BreadcrumbItem[]) => (this._breadcrumbItems = breadcrumb)
  getBreadcrumb = () => this.breadcrumbItems

  get breadcrumbItems() {
    return this._breadcrumbItems
  }
}
