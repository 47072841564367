import { ToastOptions } from '../models/toast.model'
import { makeAutoObservable } from 'mobx'

export class ToastStore {
  private toast: Array<ToastOptions>

  constructor() {
    makeAutoObservable(this)
    this.toast = []
  }

  pushToast = (toast: ToastOptions) => {
    this.toast.push(toast)
    setTimeout(() => {
      this.removeToast()
    }, 5000)
  }

  removeToast = () => this.toast.shift()
  getToast = () => this.toast
}
