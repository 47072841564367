import {
  Grid,
  Stack,
  TextField,
  FormHelperText,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
  Typography,
  Button,
} from '@mui/material'
import * as Yup from 'yup'
import { Formik } from 'formik'
import React, { Fragment, useContext, useEffect } from 'react'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { StoreContext } from '../../stores/store.context'
import { observer } from 'mobx-react-lite'
import ForgotPassword from './forgot-password'
import ButtonLoader from '../../components/button-loader'
import { trackPromise } from 'react-promise-tracker'
import { LoginRequestDto } from '../../dto/request/login-request.dto'
import { useNavigate } from 'react-router-dom'

const AuthForm: React.FC = () => {
  const [checked, setChecked] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = (event: { preventDefault: () => void }) => event.preventDefault()

  const { modalStore, authStore, i18nStore } = useContext(StoreContext)
  let navigate = useNavigate()

  useEffect(() => {}, [i18nStore.language])

  return (
    <Fragment>
      <Formik
        initialValues={{
          email: '',
          password: '',
          keepLogged: false,
          submit: null,
        }}
        validateOnMount={true}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(i18nStore.dictionary.invalidEmail)
            .max(255)
            .required(i18nStore.dictionary.requiredField),
          password: Yup.string().max(255).required(i18nStore.dictionary.requiredField),
        })}
        onSubmit={async (values) => {
          const authRequest: LoginRequestDto = {
            email: values.email,
            password: values.password,
            keepLogged: values.keepLogged,
          }

          await trackPromise(authStore.login(authRequest), 'auth')

          if (authStore.authenticated) {
            navigate('/secure/dashboard')
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isValid, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack spacing={2}>
                  <Typography variant="h2" color={'text.primary'}>
                    {i18nStore.dictionary.hello}!
                  </Typography>

                  <Typography variant="body2" color={'text.primary'}>
                    {i18nStore.dictionary.loginSubtitle}
                  </Typography>

                  <TextField
                    fullWidth
                    error={Boolean(touched.email && errors.email)}
                    id="email-login"
                    type="email"
                    value={values.email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={i18nStore.dictionary.informEmail}
                    variant="outlined"
                    label={i18nStore.dictionary.emailLabel}
                    inputProps={{
                      autoComplete: 'user-email',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                  {touched.email && errors.email && <FormHelperText error>{errors.email}</FormHelperText>}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.password && errors.password)}
                    id="password-login"
                    type={showPassword ? 'text' : 'password'}
                    value={values.password}
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    label={i18nStore.dictionary.passwordLabel}
                    variant="outlined"
                    sx={{ typography: 'body2' }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                          >
                            {showPassword ? (
                              <RemoveRedEyeIcon color="primary" />
                            ) : (
                              <VisibilityOffIcon color="primary" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    placeholder={i18nStore.dictionary.passwordPlaceholder}
                  />
                  {touched.password && errors.password && <FormHelperText error>{errors.password}</FormHelperText>}
                </Stack>
              </Grid>

              <Grid item xs={12} sx={{ mt: -1 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={(event) => setChecked(event.target.checked)}
                        name="keepLogged"
                        sx={{ color: 'primary.main' }}
                        size="small"
                      />
                    }
                    label={<Typography variant="body2">{i18nStore.dictionary.rememberAccess}</Typography>}
                  />
                  <Button
                    color="primary"
                    variant="text"
                    sx={{ textDecoration: 'underline', textUnderlineOffset: '8px' }}
                    onClick={() => modalStore.openModal(<ForgotPassword />)}
                  >
                    {i18nStore.dictionary.forgotMyPassword}
                  </Button>
                </Stack>
              </Grid>
              {errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Grid>
              )}
              <Grid item xs={12} sx={{ textAlign: 'center' }}>
                <ButtonLoader
                  loadingText={i18nStore.dictionary.authenticating + '...'}
                  text={i18nStore.dictionary.signIn}
                  area="auth"
                  disabled={!isValid}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Fragment>
  )
}

export default observer(AuthForm)
