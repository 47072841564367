import { Competence, CompetenceForm } from '../models/competence.model'
import axios from 'axios'
import { Pagination } from '../models/pagination.model'

export class CompetenceService {
  async save(competence: CompetenceForm, isDraft: boolean) {
    try {
      const res = await axios.post(process.env.REACT_APP_API + `/competence/save`, { competence, isDraft })
      const parsedResponse: Competence = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.code !== 'ERR_NETWORK') {
        throw e.response.data.message
      }
      throw new Error(e.response.statusText)
    }
  }

  async listCompetencies(
    tenandId: string,
    page: number,
    limit: number,
    orderBy?: string,
    orderByDirection?: string,
    filter?: string
  ): Promise<Pagination<Array<Competence>>> {
    try {
      const res = await axios.get(process.env.REACT_APP_API + `/competence/tenant/${tenandId}/list`, {
        params: { page, limit, orderBy, orderByDirection, filter },
      })

      const parsedResponse: Pagination<Array<Competence>> = await res.data

      parsedResponse.paginatedData = parsedResponse.paginatedData.map((competence: Competence) => {
        return {
          _id: competence._id,
          name: competence.name,
          description: competence.description,
          tenant: competence.tenant,
          status: competence.status,
          competenceValues: competence.competenceValues,
          createdBy: competence.createdBy,
          createdAt: competence.createdAt,
        }
      })

      return parsedResponse
    } catch (e: any) {
      if (e.code !== 'ERR_NETWORK') {
        throw e.response.data.message
      }
      throw new Error(e.response.statusText)
    }
  }

  async deleteCompetence(id: string): Promise<Competence> {
    try {
      const res = await axios.delete(process.env.REACT_APP_API + `/competence/${id}`)
      const parsedResponse = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.response) {
        throw e.response.data.message ?? e.response.data
      }
      throw new Error(e.response.statusText)
    }
  }

  async getById(id: string) {
    try {
      const res = await axios.get(process.env.REACT_APP_API + `/competence/${id}`)
      const parsedResponse: Competence = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.code !== 'ERR_NETWORK') {
        throw e.response.data.message
      }
      throw new Error(e.response.statusText)
    }
  }
}
