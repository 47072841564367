import { Theme } from '@mui/material/styles'

export default function Accordion(theme: Theme) {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            margin: 0,
          },
          '&:before': {
            display: 'none',
          },
          backgroundColor: theme.palette.background.default,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          marginLeft: 2,
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        root: {
          paddingLeft: '2rem',
        },
      },
    },
  }
}
