import {
  Grid,
  TextField,
  IconButton,
  FormHelperText,
  Typography,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import { useContext, useState } from 'react'
import { trackPromise } from 'react-promise-tracker'
import ButtonLoader from '../../../components/button-loader'
import { StoreContext } from '../../../stores/store.context'
import * as Yup from 'yup'
import DoneIcon from '@mui/icons-material/Done'
import ErrorIcon from '@mui/icons-material/Error'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { useNavigate } from 'react-router-dom'
import { UserData } from '../../../models/user.model'

interface ResetPasswordFormProps {
  userData: UserData
  action: string
  resetToken?: string
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = (props: ResetPasswordFormProps) => {
  const { userStore, i18nStore, toastStore } = useContext(StoreContext)
  const [redirect, setRedirect] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleClickShowPasswordConfirm = () => setShowPasswordConfirm(!showPasswordConfirm)
  let navigate = useNavigate()

  let [passwordReqs, updatePasswordReqs] = useState([
    {
      icon: <ErrorIcon color="error" />,
      text: i18nStore.dictionary.passwordMustContainUppercase,
      valid: false,
    },
    {
      icon: <ErrorIcon color="error" />,
      text: i18nStore.dictionary.passwordMustContainNumber,
      valid: false,
    },
    {
      icon: <ErrorIcon color="error" />,
      text: i18nStore.dictionary.passwordMustContainSpecialChar,
      valid: false,
    },
    {
      icon: <ErrorIcon color="error" />,
      text: i18nStore.dictionary.passwordMustContainSixChars,
      valid: false,
    },
  ])

  const regexSpecialChars: RegExp = /^.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>?].*$/
  const regexNumber: RegExp = /\d/
  const regexUppercase: RegExp = /[A-Z]/

  const validatePassword = (value: string) => {
    if (regexUppercase.test(value)) {
      passwordReqs[0].icon = <DoneIcon color="success" />
      passwordReqs[0].valid = true
    } else {
      passwordReqs[0].icon = <ErrorIcon color="error" />
      passwordReqs[0].valid = false
    }

    if (regexNumber.test(value)) {
      passwordReqs[1].icon = <DoneIcon color="success" />
      passwordReqs[1].valid = true
    } else {
      passwordReqs[1].icon = <ErrorIcon color="error" />
      passwordReqs[1].valid = false
    }

    if (regexSpecialChars.test(value)) {
      passwordReqs[2].icon = <DoneIcon color="success" />
      passwordReqs[2].valid = true
    } else {
      passwordReqs[2].icon = <ErrorIcon color="error" />
      passwordReqs[2].valid = false
    }

    if (value.length >= 6) {
      passwordReqs[3].icon = <DoneIcon color="success" />
      passwordReqs[3].valid = true
    } else {
      passwordReqs[3].icon = <ErrorIcon color="error" />
      passwordReqs[3].valid = false
    }

    updatePasswordReqs(() => {
      passwordReqs = [...passwordReqs]
      return passwordReqs
    })
  }

  return (
    <Grid container>
      <Grid item xs={12} sx={{ mt: 4 }} display="flex" justifyContent="center">
        <List>
          {passwordReqs.map((req, index: number) => {
            return (
              <ListItem disablePadding key={index}>
                <ListItemIcon>{req.icon}</ListItemIcon>
                <ListItemText primary={<Typography variant="subtitle2">{req.text}</Typography>}></ListItemText>
              </ListItem>
            )
          })}
        </List>
      </Grid>

      <Grid item xs={12} sx={{ mt: 4 }}>
        <Formik
          initialValues={{
            password: '',
            confirmPassword: '',
          }}
          validateOnMount={true}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .required(i18nStore.dictionary.requiredField)
              .min(6, i18nStore.dictionary.passwordMustContainSixChars)
              .matches(regexSpecialChars, i18nStore.dictionary.passwordMustContainSpecialChar)
              .matches(regexNumber, i18nStore.dictionary.passwordMustContainNumber)
              .matches(regexUppercase, i18nStore.dictionary.passwordMustContainUppercase),
            confirmPassword: Yup.string()
              .required(i18nStore.dictionary.requiredField)
              .oneOf([Yup.ref('password'), null], i18nStore.dictionary.passwordDontMatch),
          })}
          onSubmit={async (values) => {
            if (props.action === 'activate') {
              const userActivated = await trackPromise(
                userStore.activate(props.userData!._id, values.password),
                'pending'
              )

              if (userActivated) {
                toastStore.pushToast({
                  message: i18nStore.dictionary.userActivated,
                  type: 'success',
                })

                setRedirect(true)
                setTimeout(() => navigate('/login'), 5000)
              }
            } else {
              if (props.resetToken) {
                const passwordReseted = await trackPromise(
                  userStore.setNewPassword(props.resetToken, values.password, values.confirmPassword),
                  'pending'
                )

                if (passwordReseted) {
                  toastStore.pushToast({
                    message: i18nStore.dictionary.passwordReseted,
                    type: 'success',
                  })

                  setRedirect(true)
                  setTimeout(() => navigate('/login'), 5000)
                }
              }
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isValid, touched, values }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Grid item xs={6} display="flex" flexDirection="column" sx={{ margin: 'auto' }}>
                <TextField
                  fullWidth
                  error={Boolean(touched.password && errors.password)}
                  type={showPassword ? 'text' : 'password'}
                  value={values.password}
                  name="password"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e)
                    validatePassword(e.target.value)
                  }}
                  placeholder={i18nStore.dictionary.passwordPlaceholder}
                  variant="outlined"
                  label={i18nStore.dictionary.passwordLabel}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={handleClickShowPassword} edge="end" size="large">
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    ),
                  }}
                  inputProps={{
                    autoComplete: 'user-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
                {touched.password && errors.password && <FormHelperText error>{errors.password}</FormHelperText>}
              </Grid>

              <Grid item xs={6} display="flex" flexDirection="column" sx={{ margin: 'auto', mt: 2 }}>
                <TextField
                  fullWidth
                  error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                  type={showPasswordConfirm ? 'text' : 'password'}
                  value={values.confirmPassword}
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={i18nStore.dictionary.confirmPasswordPlaceholder}
                  variant="outlined"
                  label={i18nStore.dictionary.confirmPasswordLabel}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={handleClickShowPasswordConfirm} edge="end" size="large">
                        {showPasswordConfirm ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    ),
                  }}
                  inputProps={{
                    autoComplete: 'user-confirmPassword',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
                {touched.confirmPassword && errors.confirmPassword && (
                  <FormHelperText error>{errors.confirmPassword}</FormHelperText>
                )}
              </Grid>

              <Grid item xs={12} sx={{ textAlign: 'center', mt: 4 }}>
                {!redirect ? (
                  <ButtonLoader
                    loadingText={i18nStore.dictionary.loading}
                    text={props.action === 'activate' ? i18nStore.dictionary.activate : i18nStore.dictionary.reset}
                    area="pending"
                    disabled={!isValid || !Object.keys(passwordReqs).every((k: any) => passwordReqs[k].valid === true)}
                  />
                ) : (
                  <Grid container justifyContent="center">
                    <Grid item xs={12} sm={2}>
                      <Typography variant="h6">
                        {i18nStore.dictionary.redirecting}...
                        <LinearProgress color="secondary" />
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </form>
          )}
        </Formik>
      </Grid>
    </Grid>
  )
}

export default observer(ResetPasswordForm)
