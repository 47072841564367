import { Button, CircularProgress, Popover, SxProps, Typography } from '@mui/material'
import { MouseEventHandler, ReactElement, useState } from 'react'
import { usePromiseTracker } from 'react-promise-tracker'

type ButtonProps = {
  component?: any
  text: string | ReactElement
  type?: 'button' | 'submit' | 'reset'
  area?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
  loadingText: string
  disabled?: boolean
  sx?: SxProps
  variant?: 'text' | 'contained' | 'outlined'
  color?: 'primary' | 'secondary' | 'success' | 'warning' | 'error'
  popover?: {
    text: string
  }
}

const defaultStyle = { textTransform: 'none' }

const ButtonLoaderComponent = (props: ButtonProps) => {
  const { promiseInProgress } = usePromiseTracker({ area: props.area })

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <Button
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        component={props.component ?? 'button'}
        disableElevation
        disabled={props.disabled || promiseInProgress}
        size="medium"
        type={props.type ?? 'submit'}
        variant={props.variant ?? 'contained'}
        color={props.color ?? 'primary'}
        onClick={props.onClick}
        sx={props.sx ? props.sx : defaultStyle}
      >
        {promiseInProgress ? (
          <>
            <CircularProgress color="secondary" size={20} sx={{ mr: 2 }} />
            {props.loadingText}
          </>
        ) : (
          <>
            {props.text}
            {props.popover && (
              <Popover
                sx={{
                  pointerEvents: 'none',
                  '& .MuiPaper-root': {
                    backgroundColor: 'background.default',
                  },
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Typography variant="subtitle2" sx={{ p: 1 }}>
                  {props.popover && props.popover.text}
                </Typography>
              </Popover>
            )}
          </>
        )}
      </Button>
    </>
  )
}

export default ButtonLoaderComponent
