import axios from 'axios'
import { AuthStore } from './stores/auth.store'
import { I18nStore } from './stores/i18n.store'

export default axios.interceptors.request.use(
  async (config) => {
    const authStore = new AuthStore(new I18nStore())
    const i18n = new I18nStore()

    if (config && config.headers) {
      config.headers.Authorization = `Bearer ${authStore.getAccessToken()}`
      config.headers.language = i18n.language
      config.headers.ContentType = 'application/json'
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)
