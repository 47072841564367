import Grid from '@mui/material/Grid'
import React, { useContext, useEffect } from 'react'
import { LinearProgress, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { StoreContext } from '../../stores/store.context'

const Forbidden: React.FC = () => {
  const { i18nStore, authStore } = useContext(StoreContext)
  let navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => navigate('/login'), 5000)
  })

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Grid item xs={3} sx={{ mt: 4 }}>
          {authStore.forbidenText}
        </Grid>
        <Grid item xs={3} sx={{ mt: 2 }}>
          <>
            <Typography variant="body2" color="secondary.lighter">
              {i18nStore.dictionary.redirecting}...
              <LinearProgress color="secondary" />
            </Typography>
          </>
        </Grid>
      </Grid>
    </>
  )
}

export default Forbidden
