import { Theme } from '@mui/material/styles'

export default function Alert(theme: Theme) {
  return {
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: theme.palette.success.main,
          color: theme.palette.primary.contrastText,
          '.MuiAlert-icon': {
            color: theme.palette.primary.contrastText,
          },
        },
        standardError: {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.primary.contrastText,
          '.MuiAlert-icon': {
            color: theme.palette.primary.contrastText,
          },
        },
      },
    },
  }
}
