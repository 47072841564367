import { makeAutoObservable } from 'mobx'
import { BillingService } from '../services/billing.service'
import { ToastStore } from './toast.store'
import { Billing } from '../models/billing.model'
import { I18nStore } from './i18n.store'
import { Pagination } from '../models/pagination.model'

export class BillingStore {
  private _billings: Pagination<Array<Billing>> | null = null
  private _billingsListPage: number = parseInt(process.env.REACT_APP_DATA_INITIAL_PAGE as string)

  toastStore: ToastStore
  authStore: I18nStore

  constructor(i18nStore: I18nStore, toastStore: ToastStore) {
    makeAutoObservable(this)
    this.toastStore = toastStore
    this.authStore = i18nStore
  }

  listBillings = async (
    tenant: string,
    page: number,
    limit: number,
    orderBy?: string,
    orderbyDirection?: string,
    filter?: string
  ): Promise<void> => {
    try {
      const billingService = new BillingService()
      const billings = await billingService.listBillings(tenant, page, limit, orderBy, orderbyDirection, filter)
      this.billings = billings
    } catch (e: any) {
      this.billings = null
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e.toString(), type: 'error' })
      }
    }
  }

  get billings() {
    return this._billings
  }

  set billings(billings: Pagination<Array<Billing>> | null) {
    this._billings = billings
  }

  get billingsListPage() {
    return this._billingsListPage
  }

  set billingsListPage(page: number) {
    this._billingsListPage = page
  }
}
