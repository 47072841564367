import { makeAutoObservable } from 'mobx'
import { ElementRef } from 'react'
import { ModalOpts } from '../models/modal.model'

class ModalStore {
  private _modals: any = []
  private _opened: boolean = false
  private _children!: ElementRef<any>
  private _modalOptions: ModalOpts = {
    size: 'md',
  }

  constructor() {
    makeAutoObservable(this)
  }

  openModal = (children: ElementRef<any>, modalOpts?: ModalOpts) => {
    if (modalOpts) {
      this.modalOptions = modalOpts
    }
    this._children = children
    this._opened = true

    this._modals.push({ children, opened: true, modalOpts })
  }

  handleClose = () => this._modals.splice(-1)

  get opened() {
    return this._opened
  }

  get children() {
    return this._children
  }

  set modalOptions(opts: ModalOpts) {
    this._modalOptions = opts
  }

  get modalOptions() {
    return this._modalOptions
  }

  get modals() {
    return this._modals
  }
}

export default ModalStore
