import { Billing } from '../models/billing.model'
import axios from 'axios'
import { Pagination } from '../models/pagination.model'

export class BillingService {
  async listBillings(
    tenant: string,
    page: number,
    limit: number,
    orderBy?: string,
    orderByDirection?: string,
    filter?: string
  ) {
    try {
      const res = await axios.get(process.env.REACT_APP_API + `/billing/${tenant}/list`, {
        params: { page, limit, orderBy, orderByDirection, filter },
      })
      const parsedResponse: Pagination<Array<Billing>> = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.code !== 'ERR_NETWORK') {
        throw e.response.data.message
      }
      throw new Error(e.response.statusText)
    }
  }
}
