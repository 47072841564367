import { Box } from '@mui/material'
import { observer } from 'mobx-react-lite'

const ContentArea = ({ children }: any) => {
  return (
    <Box
      sx={{
        width: '100%',
        mt: 4,
        p: 4,
        backgroundColor: 'background.default',
        borderWidth: 1,
        borderColor: 'grey.200',
        borderStyle: 'solid',
        borderRadius: '8px',
      }}
    >
      {children}
    </Box>
  )
}

export default observer(ContentArea)
