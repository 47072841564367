import { CssBaseline, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'
import { StoreContext } from '../stores/store.context'
import GlobalStyles from './globalStyles'

const ThemeCustomization = ({ children }: any) => {
  const { themeStore, i18nStore } = useContext(StoreContext)

  return (
    <ThemeProvider theme={themeStore.theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18nStore.locale}>
        <CssBaseline />
        <GlobalStyles theme={themeStore.theme} />
        {children}
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default observer(ThemeCustomization)
