import { Box, Typography } from '@mui/material'
import { ReactElement, useContext } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { StoreContext } from '../stores/store.context'

interface childrenProps {
  children?: JSX.Element
  customText?: string
  icon?: ReactElement
}

const NotFoundData: React.FC<childrenProps> = ({ children, customText, icon }: childrenProps) => {
  const { i18nStore } = useContext(StoreContext)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mb: 4 }}>
      {icon ? icon : <SearchIcon sx={{ fontSize: '5rem', my: 2, color: 'primary.main' }} />}
      <Typography variant="body1" color="secondary.lighter">
        {customText ?? i18nStore.dictionary.notFoundData}
      </Typography>
      {children}
    </Box>
  )
}

export default NotFoundData
