import { Theme } from '@mui/material/styles'

export default function Stepper(theme: Theme) {
  return {
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            color: theme.palette.warning.main,
            '.MuiStepIcon-text': {
              fill: theme.palette.primary.dark,
            },
          },
        },
      },
    },
  }
}
