import { Grid, Stack, TextField, FormHelperText } from '@mui/material'
import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import * as Yup from 'yup'
import { useContext } from 'react'
import { StoreContext } from '../../../stores/store.context'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'

interface AssessmentFormStep1Props {
  step: number
}

const AssessmentFormStep1: React.FC<AssessmentFormStep1Props> = (props: AssessmentFormStep1Props) => {
  const { i18nStore, assessmentStore } = useContext(StoreContext)

  const formSchema = Yup.object().shape({
    namePT: Yup.string().required(i18nStore.dictionary.requiredField),
    nameEN: Yup.string().required(i18nStore.dictionary.requiredField),
    nameES: Yup.string().required(i18nStore.dictionary.requiredField),
  })

  return (
    <Formik
      initialValues={{
        namePT: assessmentStore.assessmentFormData!.name!.pt,
        nameEN: assessmentStore.assessmentFormData!.name!.en,
        nameES: assessmentStore.assessmentFormData!.name!.es,
      }}
      validationSchema={formSchema}
      validateOnMount={true}
      enableReinitialize={true}
      onSubmit={() => {}}
    >
      {({ errors, handleBlur, handleChange, touched, isValid }) => {
        return (
          <form noValidate>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.namePT && errors.namePT)}
                    type="text"
                    value={assessmentStore.assessmentFormData!.name!.pt}
                    name="namePT"
                    onBlur={handleBlur}
                    onChange={async (e) => {
                      handleChange(e)
                      const newValue = e.target.value
                      assessmentStore.assessmentFormData = {
                        ...assessmentStore.assessmentFormData,
                        name: {
                          ...assessmentStore.assessmentFormData!.name,
                          pt: newValue,
                        },
                      }
                      assessmentStore.validateStep(props.step)
                    }}
                    placeholder={i18nStore.dictionary.assessmentNamePTPlaceholder}
                    variant="outlined"
                    label={i18nStore.dictionary.assessmentNamePTLabel}
                    inputProps={{
                      autoComplete: 'assessment-name-pt',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                  {touched.namePT && errors.namePT && <FormHelperText error>{errors.namePT}</FormHelperText>}
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack spacing={1}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.nameEN && errors.nameEN)}
                    type="text"
                    value={assessmentStore.assessmentFormData!.name!.en}
                    name="nameEN"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e)
                      const newValue = e.target.value
                      assessmentStore.assessmentFormData = {
                        ...assessmentStore.assessmentFormData,
                        name: {
                          ...assessmentStore.assessmentFormData!.name,
                          en: newValue,
                        },
                      }

                      assessmentStore.validateStep(props.step)
                    }}
                    placeholder={i18nStore.dictionary.assessmentNameENPlaceholder}
                    variant="outlined"
                    label={i18nStore.dictionary.assessmentNameENLabel}
                    inputProps={{
                      autoComplete: 'assessment-name-en',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                  {touched.nameEN && errors.nameEN && <FormHelperText error>{errors.nameEN}</FormHelperText>}
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack spacing={1}>
                  <TextField
                    fullWidth
                    error={Boolean(touched.nameES && errors.nameES)}
                    type="text"
                    value={assessmentStore.assessmentFormData!.name!.es}
                    name="nameES"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e)
                      const newValue = e.target.value
                      assessmentStore.assessmentFormData = {
                        ...assessmentStore.assessmentFormData,
                        name: {
                          ...assessmentStore.assessmentFormData!.name,
                          es: newValue,
                        },
                      }
                      assessmentStore.validateStep(props.step)
                    }}
                    placeholder={i18nStore.dictionary.assessmentNameESPlaceholder}
                    variant="outlined"
                    label={i18nStore.dictionary.assessmentNameESLabel}
                    inputProps={{
                      autoComplete: 'assessment-name-es',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                  />
                  {touched.nameES && errors.nameES && <FormHelperText error>{errors.nameES}</FormHelperText>}
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={4}>
                <MobileDatePicker
                  label={i18nStore.dictionary.endDate}
                  value={assessmentStore.assessmentFormData!.endDate}
                  onChange={(newValue) => {
                    assessmentStore.assessmentFormData = {
                      ...assessmentStore.assessmentFormData,
                      endDate: newValue,
                    }
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
            </Grid>
          </form>
        )
      }}
    </Formik>
  )
}

export default observer(AssessmentFormStep1)
