import React, { useState } from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

function ProgressBarWithLabel(props: { [x: string]: any; value: any }) {
  const { value, ...other } = props
  const [percentage, setPercentage] = useState(0)
  const [position, setPosition] = useState(0)

  // Calculate percentage and update state
  React.useEffect(() => {
    setPercentage(Math.round(value))
  }, [value])

  // Calculate position of label and update state
  React.useEffect(() => {
    const updatePosition = () => {
      const bar = document.getElementById('progress-bar')
      const label = document.getElementById('progress-label')

      if (bar && label) {
        const barWidth = bar.offsetWidth
        const labelWidth = label.offsetWidth
        const newPosition = (value / 100) * (barWidth - labelWidth)
        setPosition(newPosition)
      }
    }
    updatePosition()
    window.addEventListener('resize', updatePosition)
    return () => {
      window.removeEventListener('resize', updatePosition)
    }
  }, [value])

  return (
    <Box sx={{ position: 'relative' }}>
      <LinearProgress id="progress-bar" variant="determinate" value={value} {...other} />
      <Box
        id="progress-label"
        sx={{
          position: 'absolute',
          top: '-17px',
          left: position,
          bottom: 0,
          right: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          backgroundColor: 'primary.main',
          p: 1,
          transition: 'left 0.4s ease-in-out',
        }}
      >
        <Typography variant="caption" color="primary.contrastText">{`${percentage}%`}</Typography>
      </Box>
    </Box>
  )
}

export default ProgressBarWithLabel
