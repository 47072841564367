import { Notification, NotificationCreateRequest } from '../models/notification.model'
import axios from 'axios'
import { Pagination } from '../models/pagination.model'

export class NotificationService {
  async create(notification: NotificationCreateRequest) {
    try {
      const res = await axios.post(process.env.REACT_APP_API + `/notification/create`, { notification })
      const parsedResponse: Notification = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.code !== 'ERR_NETWORK') {
        throw e.response.data.message
      }
      throw new Error(e.response.statusText)
    }
  }

  async markAs(id: string, status: string) {
    try {
      const res = await axios.post(process.env.REACT_APP_API + `/notification/${id}/mark-as/${status}`)
      const parsedResponse: Notification = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.code !== 'ERR_NETWORK') {
        throw e.response.data.message
      }
      throw new Error(e.response.statusText)
    }
  }

  async markAllAsRead(id: string) {
    try {
      const res = await axios.post(process.env.REACT_APP_API + `/notification/user/${id}/mark-all-read`)
      const parsedResponse: Notification = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.code !== 'ERR_NETWORK') {
        throw e.response.data.message
      }
      throw new Error(e.response.statusText)
    }
  }

  async deleteNotification(id: string) {
    try {
      const res = await axios.delete(process.env.REACT_APP_API + `/notification/${id}`)
      const parsedResponse: Notification = await res.data
      return parsedResponse
    } catch (e: any) {
      if (e.code !== 'ERR_NETWORK') {
        throw e.response.data.message
      }
      throw new Error(e.response.statusText)
    }
  }

  async list(
    userId: string,
    page: number,
    limit: number,
    orderBy?: string,
    orderByDirection?: string,
    filter?: string
  ): Promise<Pagination<Array<Notification>>> {
    try {
      const res = await axios.get(process.env.REACT_APP_API + `/notification/user/${userId}/list`, {
        params: { page, limit, orderBy, orderByDirection, filter },
      })
      const parsedResponse: Pagination<Array<Notification>> = await res.data
      parsedResponse.paginatedData as Notification[]
      return parsedResponse
    } catch (e: any) {
      if (e.code !== 'ERR_NETWORK') {
        throw e.response.data.message
      }
      throw new Error(e.response.statusText)
    }
  }
}
