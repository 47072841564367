import { makeAutoObservable } from 'mobx'
import DictionaryEN from '../assets/i18n/en'
import DictionaryES from '../assets/i18n/es'
import DictionaryPT from '../assets/i18n/pt'
import { Language } from '../models/i18n.model'
import 'dayjs/locale/pt-br'
import 'dayjs/locale/en'
import 'dayjs/locale/es'

export class I18nStore {
  private _language: Language | null = null
  private _dictionary: any = DictionaryEN
  private _locale: 'pt-br' | 'en' | 'es' = 'en'

  constructor() {
    makeAutoObservable(this)
  }

  set language(newLanguage: Language) {
    this._language = newLanguage
    localStorage.setItem('cm_language', newLanguage)
    this.locale = newLanguage !== 'pt' ? newLanguage : 'pt-br'
  }

  get language(): Language {
    let currentLanguage = this._language || 'en'

    if (localStorage.getItem('cm_language') as Language) {
      currentLanguage = localStorage.getItem('cm_language') as Language
    }

    switch (currentLanguage) {
      case 'pt':
        this.dictionary = DictionaryPT
        break
      case 'en':
        this.dictionary = DictionaryEN
        break
      case 'es':
        this.dictionary = DictionaryES
        break
    }
    return currentLanguage ?? 'pt'
  }

  get dictionary() {
    return this._dictionary
  }

  set dictionary(newDictionary: any) {
    this._dictionary = newDictionary
  }

  set locale(locale: 'pt-br' | 'en' | 'es') {
    this._locale = locale
  }

  get locale() {
    let currentLanguage = this._language

    if (localStorage.getItem('cm_language') as Language) {
      currentLanguage = localStorage.getItem('cm_language') as Language
      this.locale = currentLanguage !== 'pt' ? currentLanguage : 'pt-br'
    }

    return this._locale
  }
}
