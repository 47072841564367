import { LoginRequestDto } from '../dto/request/login-request.dto'
import axios from 'axios'
import { Roles } from '../models/roles.model'

export class AuthService {
  async login(loginRequest: LoginRequestDto) {
    try {
      const res = await axios.post(process.env.REACT_APP_API + `/auth`, loginRequest)
      return res.data.token
    } catch (e: any) {
      if (e && e.code) {
        if (e.code !== 'ERR_NETWORK' && e.code !== 'ERR_BAD_RESPONSE') {
          throw e.response.data
        }
      }
      throw new Error(e.toString())
    }
  }

  async getMenu(role: Roles) {
    try {
      const res = await axios.get(process.env.REACT_APP_API + `/security/menu/${role}`)
      return res.data
    } catch (e: any) {
      if (e && e.code) {
        if (e.code !== 'ERR_NETWORK' && e.code !== 'ERR_BAD_RESPONSE') {
          throw e.response.data
        }
      }
      throw new Error(e.toString())
    }
  }

  async validateAccess(role: Roles, route: string) {
    try {
      const res = await axios.post(process.env.REACT_APP_API + `/security/validate-access/${role}`, { route: route })
      return res.data
    } catch (e: any) {
      if (e && e.code) {
        if (e.code !== 'ERR_NETWORK' && e.code !== 'ERR_BAD_RESPONSE') {
          throw e.response.data
        }
      }
      throw new Error(e.toString())
    }
  }
}
