import { makeAutoObservable } from 'mobx'
import { Notification, NotificationCreateRequest } from '../models/notification.model'
import { Pagination } from '../models/pagination.model'
import { NotificationService } from '../services/notification.service'
import { AuthStore } from './auth.store'
import { I18nStore } from './i18n.store'
import { ToastStore } from './toast.store'

export class NotificationStore {
  private _notifications: Pagination<Array<Notification>> | null = null
  private _notReadedAmount: number = 0

  toastStore: ToastStore
  authStore: AuthStore
  i18nStore: I18nStore

  constructor(authStore: AuthStore, i18nStore: I18nStore, toastStore: ToastStore) {
    makeAutoObservable(this)
    this.authStore = authStore
    this.toastStore = toastStore
    this.i18nStore = i18nStore
  }

  async create(notification: NotificationCreateRequest): Promise<Notification | undefined> {
    try {
      const notificationService = new NotificationService()
      const saved = await notificationService.create(notification)
      return saved
    } catch (e: any) {
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e, type: 'error' })
      }
    }
  }

  async markAs(id: string, status: string): Promise<Notification | undefined> {
    try {
      const notificationService = new NotificationService()
      const saved = await notificationService.markAs(id, status)
      return saved
    } catch (e: any) {
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e, type: 'error' })
      }
    }
  }

  async markAllAsRead(id: string): Promise<Notification | undefined> {
    try {
      const notificationService = new NotificationService()
      const saved = await notificationService.markAllAsRead(id)
      return saved
    } catch (e: any) {
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e, type: 'error' })
      }
    }
  }

  async deleteNotification(id: string): Promise<Notification | undefined> {
    try {
      const notificationService = new NotificationService()
      const deleted = await notificationService.deleteNotification(id)
      return deleted
    } catch (e: any) {
      if (this.toastStore) {
        this.toastStore.pushToast({ message: e, type: 'error' })
      }
    }
  }

  async list(
    userId: string,
    page: number,
    limit: number,
    orderBy?: string,
    orderbyDirection?: string,
    filter?: string
  ): Promise<void> {
    try {
      const notificationService = new NotificationService()
      const res: Pagination<Array<Notification>> = await notificationService.list(
        userId,
        page,
        limit,
        orderBy,
        orderbyDirection,
        filter
      )

      if (res && page === 1) {
        this.notifications = res
      } else {
        this.pushNotifications(res.paginatedData)
      }
      this.filterUnread()
    } catch (e: any) {
      this.notifications = null
      this.toastStore.pushToast({ message: e.toString(), type: 'error' })
    }
  }

  set notifications(notifications: Pagination<Array<Notification>> | null) {
    this._notifications = notifications
  }

  get notifications() {
    return this._notifications
  }

  set notReadedAmount(notReaded: number) {
    this._notReadedAmount = notReaded
  }

  get notReadedAmount() {
    return this._notReadedAmount
  }

  pushNotifications = (notifications: Array<Notification>) =>
    (this._notifications!.paginatedData = [...this._notifications!.paginatedData, ...notifications])

  filterUnread = () => {
    const notifications = this._notifications!.paginatedData
    const notReaded = notifications.filter((notification: Notification) => notification.status === 'unread')
    this._notReadedAmount = notReaded.length ?? 0
  }
}
