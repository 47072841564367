import { Box, Grid, Typography } from '@mui/material'
import { Fragment, useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../stores/store.context'
import { UserData } from '../../models/user.model'
import { useSearchParams } from 'react-router-dom'
import ResetPasswordForm from '../user/forms/reset-password'
import { observer } from 'mobx-react-lite'

const UserSetNewPassword: React.FC = () => {
  const { userStore, i18nStore } = useContext(StoreContext)
  const [userData, setUserData] = useState<UserData | null>(null)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const checkResetToken = async () => {
      const resetToken = searchParams.get('resetToken')
      if (resetToken) {
        const userData = await userStore.checkResetToken(resetToken)

        if (userData && userData.email) {
          setUserData(userData)
        }
      }
    }
    checkResetToken()
  }, [searchParams, userStore])

  return (
    <Fragment>
      <Grid container>
        {userData ? (
          <Fragment>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant="h1" textAlign="center">
                  {i18nStore.dictionary.welcome + ', '}
                </Typography>
                <Typography variant="h1" textAlign="center" color="primary.main">
                  &nbsp;{userData.firstName}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sx={{ mt: 4 }}>
              <Typography variant="subtitle1" textAlign="center">
                {i18nStore.dictionary.setNewPasswordMessage}
              </Typography>
            </Grid>

            <ResetPasswordForm
              userData={userData}
              action="reset"
              resetToken={searchParams.get('resetToken') as string}
            />
          </Fragment>
        ) : (
          <Grid item xs={12} textAlign="center" sx={{ mt: 4 }}>
            <Typography variant="h1" color="error">
              {i18nStore.dictionary.invalidToken}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Fragment>
  )
}

export default observer(UserSetNewPassword)
