import { Box, Button, FormHelperText, Stack, TextField, Typography } from '@mui/material'
import { Formik } from 'formik'
import { useContext } from 'react'
import { trackPromise } from 'react-promise-tracker'
import * as Yup from 'yup'
import ButtonLoader from '../../components/button-loader'
import { StoreContext } from '../../stores/store.context'
import CheckCircleOutlineOutlined from '@mui/icons-material/CheckCircleOutlineOutlined'

const ForgotPassword: React.FC = () => {
  const { userStore, modalStore, i18nStore } = useContext(StoreContext)

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          password: '',
          submit: null,
        }}
        validateOnMount={true}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(i18nStore.dictionary.invalidEmail)
            .max(255)
            .required(i18nStore.dictionary.requiredField),
        })}
        onSubmit={async (values, helper) => {
          const resetPassword = trackPromise(userStore.resetPassword(values.email), 'reset-password')
          resetPassword
            .then(() => {
              modalStore.handleClose()
              modalStore.openModal(
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <CheckCircleOutlineOutlined color="success" sx={{ fontSize: '5rem' }} />

                  <Typography
                    variant="body2"
                    color={'secondary.lighter'}
                    align="center"
                    sx={{ my: 3, maxWidth: '395px' }}
                  >
                    {i18nStore.dictionary.instructionsResetPasswordSendByEmail}
                  </Typography>

                  <Button
                    disableElevation
                    size="large"
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => modalStore.handleClose()}
                    sx={{ borderRadius: 10, paddingX: 4, paddingY: 1.5, textTransform: 'none', minWidth: '195px' }}
                  >
                    {i18nStore.dictionary.ok}
                  </Button>
                </Box>
              )
            })
            .catch((e) => {
              helper.setFieldError('email', i18nStore.dictionary.failedToSendMail)
            })
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isValid, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Stack>
              <Typography textAlign={'center'} variant="h4" color={'text.primary'}>
                {i18nStore.dictionary.resetPasswordTitle}
              </Typography>

              <TextField
                fullWidth
                error={Boolean(touched.email && errors.email)}
                id="email-login"
                type="email"
                value={values.email}
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={i18nStore.dictionary.informEmail}
                variant="outlined"
                label={i18nStore.dictionary.emailLabel}
                sx={{ marginTop: 4 }}
                inputProps={{
                  autoComplete: 'user-email',
                  form: {
                    autocomplete: 'off',
                  },
                }}
              />
              {touched.email && errors.email && <FormHelperText error>{errors.email}</FormHelperText>}

              <Box sx={{ marginTop: 4, alignSelf: 'center' }}>
                <ButtonLoader
                  loadingText={i18nStore.dictionary.loading}
                  text={i18nStore.dictionary.send}
                  area="reset-password"
                  disabled={!isValid}
                />
              </Box>
            </Stack>
          </form>
        )}
      </Formik>
    </>
  )
}

export default ForgotPassword
